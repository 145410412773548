const Footer = () => {
  return (
    <div className="tags-sec full-width">
      <ul>
      </ul>
      <div className="cp-sec">
        <img style={{ width: '88px', height: "100%" }} src="/assets/images/ImREAL.png" alt="" />
        <p>
          <img src="/assets/images/cp.png" alt="" />
          Copyright 2022
        </p>
      </div>
    </div>
  );
};
export default Footer;